import React from 'react'
import { Link } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// eslint-disable-next-line import/no-extraneous-dependencies
import { arrayOf, func, node, oneOfType, shape, string } from 'prop-types'

const LinkButton = ({ children, icon, onClick, size, style, to, type }) => {
  let sizeClass = ''

  if (size === 'small') {
    sizeClass = 'ant-btn-sm'
  }

  if (size === 'large') {
    sizeClass = 'ant-btn-lg'
  }

  // This is tightly coupled to antd, but tries to anticipate an introduction of a similar component
  return (
    <Link
      className={`ant-btn ant-btn-${type} ${sizeClass}`}
      style={style}
      to={to}
      onClick={onClick}
    >
      {icon && <FontAwesomeIcon icon={icon} />}
      {children}
    </Link>
  )
}

LinkButton.defaultProps = {
  icon: undefined,
  onClick: undefined,
  size: 'normal',
  style: undefined,
  type: 'default',
}

LinkButton.propTypes = {
  children: oneOfType([arrayOf(node), node]).isRequired,
  icon: shape({}),
  onClick: func,
  size: string,
  style: shape({}),
  to: string.isRequired,
  type: string,
}

export default LinkButton
